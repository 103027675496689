.drawerPaper{
    min-width: 400px;
    background-color: #f5f5f5!important;
}
.drawerPaper .container{
    margin-top: 35px;
    padding: 1em;

    box-sizing: border-box;
}
.drawerPaper .container.marginTop{
    margin-top: 40px;
    transition: all .5s ease-in-out;
}
.drawerPaper .container.marginTopDown{
    margin-top: 10px;
    transition: all .5s ease-in-out;
}
.drawerPaper .title{
    display: flex;
    justify-content: center;
    margin-left: 60px;
}
.drawerPaper .check {
    color: green;
}
.drawerPaper .fail{
    color:darkred;
}
.drawerPaper .listText{
    font-size: 1em;
}
.drawerPaper .listText .secondary{
    max-width: 280px;
}